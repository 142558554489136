














































import { Component, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/components/form/fields/abstract-field'
import moment from 'moment'
import MultiSelect from 'vue-multiselect'
import _ from 'lodash'
import DatePicker from 'vue2-datepicker'
import SelectOption from '@/shared/classes/components/form/select-option';

@Component({
  components: { MultiSelect, DatePicker }
})
export default class DateFilter extends AbstractField {
  select: any = {}
  dateIncrement: number = 0
  dateValue: (Date|null)[] = [
    null, null
  ]

  $refs!: any

  @Watch('dateValue', { deep: true }) onDateValueChange(newVal:any) {
    this.value.from = moment(newVal[0]).format('YYYY-MM-DD')
    this.value.to = moment(newVal[1]).format('YYYY-MM-DD')
  }

  mounted(): void {
    if (this.value.from || this.value.to) this.select = _.last(this.field.selectOptions)
    if (this.value.from && this.value.to) {
      this.dateValue = [
        new Date(this.value.from), new Date(this.value.to)
      ]
    }
  }

  incrementMonth(subtract: boolean = false) {
    this.dateIncrement += subtract ? -1 : 1
    if (this.dateIncrement === 0) {
      const currentMonthOption = this.field.selectOptions.find((option: SelectOption) => option.key === 'current-month')
      this.setValue(currentMonthOption)
      this.select = currentMonthOption
      return
    }
    if (this.dateIncrement === -1) {
      const lastMonthOption = this.field.selectOptions.find((option: SelectOption) => option.key === 'last-month')
      this.setValue(lastMonthOption)
      this.select = lastMonthOption
      return
    }
    this.select = {
      key: 'custom',
      title: moment(this.value.to).locale('lt').format('YYYY MMMM')
    }
    this.field.setValue({
      from: moment()
        .add(this.dateIncrement, 'M')
        .startOf('month')
        .format('YYYY-MM-DD'),
      to: moment()
        .add(this.dateIncrement, 'M')
        .endOf('month')
        .format('YYYY-MM-DD')
    })
    this.dateValue = [
      new Date(this.value.from), new Date(this.value.to)
    ]
  }

  setValue(value: Record<string, string>) {
    if (value.key === 'select') {
      this.field.setValue({
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      })
      this.dateValue = [
        new Date(this.value.from), new Date(this.value.to)
      ]
      this.$refs[`${this.field.key}-range`].openPopup()
      return
    }

    if (value.key === 'last-month') {
      this.dateIncrement = -1
      this.field.setValue({
        from: moment()
          .subtract(1, 'M')
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .subtract(1, 'M')
          .endOf('month')
          .format('YYYY-MM-DD')
      })
      this.dateValue = [
        new Date(this.value.from), new Date(this.value.to)
      ]
      return
    }

    if (value.key === 'current-month') {
      this.dateIncrement = 0
      this.field.setValue({
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
        to: moment()
          .endOf('month')
          .format('YYYY-MM-DD')
      })
      this.dateValue = [
        new Date(this.value.from), new Date(this.value.to)
      ]
      return
    }

    this.value = {
      from: '',
      to: ''
    }
  }
}
